import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from '@reach/router';
import Sidebar from 'components/sidebar';
import HomeLayout from 'components/home-layout';
import PostFooter from 'components/post-footer';
import Links from 'components/links';
import Image from 'components/image';
import SEO from 'components/seo';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
import StartHereLinks from 'components/start-here-links';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomeLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Opening Letter`}</h1>
    <p>{`hola`}</p>
    <p>{`shaan here. This is a vault where you can see behind the scenes content about some of my side projects (eg. raising a $2.5M fund, growing an ecom store to $200k/month in 6 months, etc.)`}</p>
    <p>{`This was part of an experiment I did called the “All Access Pass”. It was a 42 day sprint on these projects, and I blogged about what I did every day. `}</p>
    <p>{`This vault has all those emails we sent over the 42 days. `}</p>
    <p>{`We had a full community rockin’ during those 42 days, and I didn’t plan on selling these afterwards. But I thought fuck it, I already did the hard work, why not sell this at a dirt cheap price for people who were too cheap to buy the original ($600). `}</p>
    <p>{`One of our community members volunteered to create this vault (thanks Bruce!) so we could give people access. `}</p>
    <p>{`We’ve organized them into four sections:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`The Fund`}</strong>{` - how I raised a $2.6M/year fund in 30 days`}</li>
      <li parentName="ol"><strong parentName="li">{`Ecommerce`}</strong>{`  - showing some of my best tools & tactics for ecom`}</li>
      <li parentName="ol"><strong parentName="li">{`Growing an audience`}</strong>{` - my failed attempt at growing a big newsletter in 30 days`}</li>
      <li parentName="ol"><strong parentName="li">{`Wisdom`}</strong>{` - random frameworks & life advice `}</li>
    </ol>
    <p>{`These emails were all sent in realtime as things were evolving, so it’s a little messy and not neatly organized like a book. But hey that was the point, I wanted to show you how I did it, while I did it, messiness included. `}</p>
    <p>{`Not all of it is gold...but there are golden nuggets in here. If you’re a hustler you’ll find those nuggets, and apply them in your business. `}</p>
    <p><strong parentName="p">{`There are two tabs:`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`Big Ideas - 15 of the best frameworks & templates formatted nicely with custom illustrations`}</li>
      <li parentName="ul">{`Emails - 40 emails I sent, organized by which project they referred to. `}</li>
    </ul>
    <p>{`So get busy. Click around. You don’t have to go in order, just follow your curiosity. `}</p>
    <p>{`And let me know what you think (`}<a parentName="p" {...{
        "href": "mailto:puri.shaan@gmail.com"
      }}>{`puri.shaan@gmail.com`}</a>{`) `}</p>
    <p>{`Later,`}<br parentName="p"></br>{`
`}{`-`}{` shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      